import React from "react"
import styled from "styled-components"
import LoginStateContext from "../contexes/LoginStateContext"
import LoginControls from "../components/LoginControls"
import { withTranslation } from "gatsby-plugin-react-i18next"
import withSimpleErrorBoundary from "../util/withSimpleErrorBoundary"
import { normalizeExerciseId } from "../util/strings"
import Quiz from "moocfi-quizzes"
import { Paper } from "@material-ui/core"
import { accessToken } from "../services/moocfi"
import getCourseSettings from "../util/courseSettingsWithLanguage"

const StyledPaper = styled(Paper)`
  overflow: hidden;
  margin: 2rem 0 2rem 0;
  box-shadow: rgba(0, 0, 0, 0.3) 0px 8px 40px -12px !important;
  border-radius: 1rem !important;
`

class QuizPartial extends React.Component {
  static contextType = LoginStateContext

  render() {
    const { id, t } = this.props

    const CourseSettings = getCourseSettings()
    let languageId
    switch (CourseSettings.language) {
      case "en":
        languageId = "en_US"
        break
      case "sv":
        languageId = "sv_SE"
        break
      case "fi":
        languageId = "fi_FI"
        break
      default:
        languageId = "en_US"
    }

    const loginPrompt = (
      <div style={{ padding: "1rem", textAlign: "center" }}>
        <h4>{t("loginToSeeExercise")}</h4>
        <p>{t("loginToSeeExerciseExplanation")}</p>
        <LoginControls />
      </div>
    )

    if (!id) {
      return <div>{t("quizIdMissing")}</div>
    }
    return (
      <StyledPaper id={normalizeExerciseId(`quiz-${id}`)}>
        <Quiz
          id={id}
          languageId={languageId}
          accessToken={accessToken()}
          backendAddress="https://quizzes.mooc.fi"
          customContent={{ Login: loginPrompt }}
        >
          {this.props.children}
        </Quiz>
      </StyledPaper>
    )
  }
}

export default withTranslation("common")(withSimpleErrorBoundary(QuizPartial))
